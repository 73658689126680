 * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

} 
body {
  background-color: black;
}

.App-backgroundImage {
max-width: 100%;
height: 100vh;
background-size: cover;
background-repeat: no-repeat;
opacity: 0.5;

}
 .background-container {
 height: 100%;
  width: 100%;
  background-color: black;
} 

.background {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  
  height: 50%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* navbar menu button */
.navbar-expand-xl {
  padding-top: 0px;
  padding-bottom: 0px;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
}
.navbar-toggler-icon {
  height: 1.5em;
  width: 1.5em;
}
.navbar-toggler:focus {
  box-shadow: white;
}
.navbar-nav, .me-auto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0px;
  margin-top: 10px;
  margin-bottom: 0;
  list-style: none;
  
}
.me-auto {
  align-items: center;
}
.navbar-collapse {
  flex-grow: 0!important;
}
.navbar-nav {
  font-size: 20px;
}
/* Cards */
.card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  margin: -1px;

}
.card-img, .card-img-top, .card {
  border-top-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
}
.card {
  --bs-card-border-color: black;
  --bs-card-border-width: 0px;
}

/*CardSetMain*/
.card-title {
  display: flex;
  justify-content: center;
  color: white;
}
/*CardFinger component*/
.cardFinger-component {
  background-color: black;
}
.card-img, .card-img-top {
  background-color: black;
}
/*Modal menu*/
.modal-body {
  background-color: black;
}
.modal-menu-img {
max-width: 100%;
background-size: cover;
background-repeat: no-repeat;
display: flex;
}

/*CarouselText*/
.carousel-text {
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;
  text-align: center;
  height:90%;
  width: auto;
  border-radius: 0;
  font-weight: bold;
  font-size: 20px;
}
.carousel-text-card{
  font-size: 15px;
}
/*Offers page*/
.Offers {
  background-color: black;
}
/*FingerFood page*/
.FingerFood {
  background-color: black;
  color: white;
}
.h-100 {
  background-color: black;
}
.row {
--bs-gutter-x: 0px;
}
.card-body {
  text-align: center;
}
/*GalleryComp*/
.gallery-container{
  background-color: black;
  padding: 5px 5px 5px 5px;
}
  .navbar>.container-fluid {
background-color: black;
  }

/* footer */
.logoMono-footer {
  width: 200px;
}
.border-bottom {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
}
.carousel-control-next-icon::after, .carousel-control-prev-icon::after {
  content: none;
}
@media screen and (min-width: 600px) {
  .App-backgroundMobile{
    display: none;
  } 
.BackgroundCarouselMobile {
  display: none;
}
}
 @media screen and (max-width: 480px) {
    /* .App-backgroundImage {
    display: none;
    height: 100vh;
  }    */
  .BackgroundCarousel {
    display: none;
  }
  .mobile-container{
    min-width: 100%;
  }
   .App-backgroundMobile {
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 67px;
    width: 100%;
    padding-left: 8px;
    } 
     /* .logo-mono {
      display: none;
    }  */
    .carousel-text-card {
      font-size: 13px;
    }
    .mobile {
      font-size: 15px;
    }
}
